import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";

import virtualInternet from "../../Assets/Projects/VirtualInternet.png";
import das from "../../Assets/Projects/DAS.jpeg";
import space from "../../Assets/Projects/Space.png";
import traffic from "../../Assets/Projects/traffic.jpg";
import terrarium from "../../Assets/Projects/Terrarium.jpg";
import sarcasm from "../../Assets/Projects/sarcasm.jpg";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={virtualInternet}
              isBlog={true}
              title="Virtual Internet Emulator"
              description="Implementation of SEED emulator to create a lab environment for the purpose of Cybersecurity education. The program provides a contained environment that simulates a network of a given size and packets within it"
              ghLink="https://github.com/sitzmaa/VirtualInternet"
              demoLink="https://blog.alexsitzman.com/blogs/virtual-internet-emulation-for-cybersecurity-training"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={das}
              isBlog={false}
              title="DAS Driver Windows/NetBSD"
              description="A driver for a digital analog converter, utilizing a PCI bus. Built in both NetBSD and Windows environments, the device read from a varying voltage source and converts it into machine readable samples with time stamps"
              ghLink="https://github.com/sitzmaa/DASDriver"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={space}
              isBlog={false}
              title="Unity Horror Game"
              description="A space themed survival horror game built in unity. The game will ultilize a fully functional terminal to manage and control the player's spacecraft. The game will encourage players to write scripts and manage resources to survive"
              ghLink="https://github.com/sitzmaa/UnitySpaceGamePage"
        
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={traffic}
              isBlog={false}
              title="Traffic Simulator"
              description="A simulation of traffic and intersections built in unity. The cars have a real time pathing algorithm and follow standard laws. The simulation can be joined by multiple users who can manipulate the parameters of the simulation in real time."
              ghLink="https://github.com/sitzmaa/TrafficSimulator"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={terrarium}
              isBlog={false}
              title="Terrarium Monitor"
              description="Built as a hobby project, the device monitors and regulates the temperature of a terrarium. Built using an arduino mega, it has a fully interactive display for user ease of use. Images to come"
              // ghLink="https://github.com/soumyajit4419/AI_For_Social_Good"
              // demoLink="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstley" <--------Please include a demo link here
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={sarcasm}
              isBlog={true}
              title="Sarcasm Detection Langauge Model"
              description="A language model trained on data sets from Kaggle. This problem is composed of three different models meant to measure the success rates of various types of neural networks when it comes to hard context based text classification"
              //ghLink=""
              demoLink="https://blog.alexsitzman.com/blogs/sarcasm-detection-natural-language-processing"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
