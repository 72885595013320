import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className="purple">Alexander Sitzman </span>
            from <span className="purple"> Seattle, Washington.</span>
            <br />
            <br/>
            I am currently working as a Freelance Web Developer for local Bellingham Businesses
            <br />
            <br/>
            I have a Bachelor's Degree in Computer Science from Western Washington University
            <br />
              <br />
              In both my professional and my personal pursuits, 
              I draw inspiration from my passion for creativity 
              and artistry. Whether I'm immersed in designing game mechanics 
              or tackling complex coding tasks, I prioritize thoughtful
               planning and meticulous attention to detail. 
               Balancing my professional responsibilities with my creative 
               endeavors requires effective time management and disciplined scheduling. 
               By dedicating time to both pursuits, I'm continually refining my skills 
               in <i><b className="purple">Web Development and Game Design</b></i>, driven by a desire to bring my 
               ideas to life and make meaningful contributions to the field.
               <br/>
              <br/>
               In my academic and personal endeavors, 
              I've always approached challenges with a problem-solving mindset. 
              Whether faced with academic hurdles or complex coding tasks, 
              I view each obstacle as an opportunity for growth and learning. 
              My experiences navigating the complexities of computer systems 
              and operating environments have strengthened my resolve and sharpened 
              my problem-solving skills. With unwavering determination and resilience, 
              I tackle each challenge head-on, driven by a passion for discovery and innovation.
              <br/>
              <br/>

            Apart from coding, some other activities that I love to do!
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Playing Games
            </li>
            <li className="about-activity">
              <ImPointRight /> Reading Science Fiction
            </li>
            <li className="about-activity">
              <ImPointRight /> Hiking
            </li>
          </ul>

        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
